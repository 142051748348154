import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Foot  from '../components/Foot'
import ads from '../assets/images/add_campaing.webp'
import ResponsiveIframe from '../components/ResponsiveIframe'

const EmailMarketing = props => (
  <Layout>
  <Helmet
  htmlAttributes={{ lang: 'en' }}
  title="EMAIL MARKETING- Bulls i Marketing"
  meta={[
    { name: 'description', content: 'Email Marketing Strategy Development, Guidance, Content Creation and more.' },
    { name: 'keywords', content: 'Bulls i Marketing, Email Marketing, Content Creation, AB TESTING' },
  ]}
></Helmet>

    <div id="main" className="wrapper style2">
      <div className="container">
      <article>
        <header className="major">
          <h2>Email Marketing</h2>
        </header>


        <section>
          <p>
          Our primary email marketing deliverables per client are specific and are definitely not limited to the following:
          </p>       
          <ResponsiveIframe url="https://fast.wistia.net/embed/iframe/i3xsc5jdnj"></ResponsiveIframe>
          <ol>
            <li>Email Marketing Strategy Development 
            </li>
          
            <li>Weekly or monthly meeting you to review campaign analytics  
            </li>
            <li>Content Creation – built while keeping your email marketing goals in mind  
            </li>
            <li>Editing your email marketing campaign in context to your industry  
            </li>
            <li>Design Template Development   
            </li>
            <li>A/B Testing Consultation (Content, Subject Lines, Templates, etc.)    
            </li>
            <li>Assuring that your messages are functional and impressive across platforms and email clients, including mobile devices and tablets</li>
            <li>Training you and your team on campaign designing and maintenance
            </li>
            <li>Contact list development to help you reach the maximum number of potential customers
            </li>
            <li>Guidance on best practices for lead nurturing</li>
            <li>Guidance on post-purchase email campaigns to help your brand increase repeat purchases and up-sells.</li>
          </ol>
        </section>

        </article>
      </div>
    </div>
    <Foot className="style3" title="Start your Email Marketing now!"/>
  </Layout>
)

export default EmailMarketing
